import React from "react";
import { Header } from "../../Components/Header/Header";
import s from "./Home.module.scss";
import ds from "../../images/ds.svg";
import tg from "../../images/hometg.jpg";
import homemap from "../../images/homemaplogo.svg";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <div className={s.home}>
      <Header />
      <div className={s.home_wrapper}>
        <div className={s.home_left}>
          <div className={s.home_left}>
            <p className={s.home_left_serverInfo}>
              <span>Поддержка версии — 1.12.2</span>
              <span>
                <b></b> Игроков онлайн: 0
              </span>
            </p>

            <h1 className={s.home_left_title}>Grand Theft Minecraft</h1>
            <h2 className={s.home_left_desc}>
              Масштаб вашего видения, наша кубическая миссия. Присоединяйся к
              нам, чтобы увидеть этот потрясающий режим!
            </h2>
            <Link className={s.home_left_map}>
              <img src={homemap} alt="" />
              Наша карта
            </Link>
            <div className={s.home_left_links}>
              <Link>
                <img src={ds} alt="" />
                <p>
                  Discord
                  <span>discord.gg/CoreCraft</span>
                </p>
              </Link>
              <Link>
                <img src={tg} alt="" />
                <p>
                  Telegram <span>@CoreCraft</span>
                </p>
              </Link>
            </div>
          </div>
        </div>

        <div className={s.home_right}>
          <img src={require("../../images/7eX3H9o 1.png")} alt="" />
        </div>
      </div>
    </div>
  );
};
