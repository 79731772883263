import React from "react";
import s from "./Header.module.scss";
import "./Header.css"
import logo from "../../images/Logo.svg";
import ds from "../../images/ds.svg";
import vk from "../../images/vk.svg";
import { NavLink, Link } from "react-router-dom";

export const Header = () => {
  return (
    <header className={s.header}>
      <nav className={s.nav}>
        <div className={s.nav_logo}>
          <img className={s.nav_logo_img} src={logo} alt="" />
          <h1 className={s.nav_logo_text}>CORECRAFT</h1>
        </div>
        <section className={s.nav_links}>
          <NavLink className={s.nav_link} to="/">
            Главная
          </NavLink>
          <NavLink className={s.nav_link} to="/about">
            О режиме
          </NavLink>
          <NavLink className={s.nav_link} to="/news">
            Новости
          </NavLink>
          <NavLink className={s.nav_link} to="/top">
            Топы
          </NavLink>
          <NavLink className={s.nav_link} to="/shop">
            Магазин
          </NavLink>
        </section>

        <section className={s.nav_contactLinks}>
          <Link className={s.nav_contactLink}>
            <img src={ds} alt="" />
          </Link>
          <Link className={s.nav_contactLink}>
            <img src={vk} alt="" />
          </Link>
        </section>
      </nav>
    </header>
  );
};
